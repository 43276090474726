import { smartRedirect } from './smartRedirect';
import { processingModal } from './helpers/processing_modal';
import SmartyForm from './helpers/smarty_form';

const smartyForm = new SmartyForm();

$(document).on('click', '.retract-amendment-btn', function(e) {
  e.preventDefault();
  if ($('#retract-amendment-modal').length < 1) {
    $('body').prepend('<div id="retract-amendment-modal"/>')
  }

  const url = $(this).attr('href');

  $.ajax({
    url: url,
    method: 'GET',
    success: function(response) {
      $('#retract-amendment-modal').replaceWith(response);
      const modal = $('#retract-amendment-modal')
      modal.modal('show');
    }
  })
});

$(document).on('submit', '#account-retract-amendment', function(e) {
  e.preventDefault();

  processingModal.show();

  smartyForm.process({
    form: $(e.target),
    onSuccess: () => smartRedirect(window.location.origin + window.location.pathname),
    onError: () => { processingModal.hide() }
  });
});
