window.adminVenuesMaxBookingDatesFields = () => {
  const defaultOptions = {
    format: 'D, dd M yyyy',
    autoclose: true,
    startDate: '+0d',
    weekStart: 1,
    container: '#modal_form'
  };

  function setAtDateFromToDate(field, selectedDate) {
    if (field.datepicker('getDate') > selectedDate) {
      field.datepicker('setDate', selectedDate);
    }
    field.datepicker('setEndDate', selectedDate);
  }

  $('#venue_max_booking_date, #venue_max_booking_date_existing_customers').datepicker(defaultOptions);

  $('#venue_max_booking_date_update_to').datepicker({
    ...defaultOptions, startDate: '+1d' 
  }).on('changeDate', function (selected) {
    setAtDateFromToDate($('#venue_max_booking_date_update_at'), selected.date);
  });

  $('#venue_max_booking_date_existing_customers_update_to').datepicker({
    ...defaultOptions, startDate: '+1d' 
  }).on('changeDate', function (selected) {
    setAtDateFromToDate($('#venue_max_booking_date_existing_customers_update_at'), selected.date);
  });

  $('#venue_max_booking_date_update_at, #venue_max_booking_date_existing_customers_update_at').datepicker({ ...defaultOptions, startDate: '+1d' });

  $('#max_booking_date_update, #max_booking_date_existing_customers_update').on('click', function(e) {
    if ($('.max_booking_date_update_fields', $(this).parent()).hasClass('hide')) {
      $('.max_booking_date_update_fields', $(this).parent()).removeClass('hide');
    } else {
      $('.max_booking_date_update_fields', $(this).parent()).addClass('hide');
    }
    e.preventDefault();
  })

  $('.max_booking_date_update_fields select').on('change', function() {
    const wrapper = $(this).parents('.max_booking_date_update_fields');

    if ($(this).val() == 'Immediately') {
      $('.max_booking_date_update_at input', wrapper).val('');
      $('.max_booking_date_update_at', wrapper).addClass('hide');
      $('.max_booking_date_update_to input', wrapper).val('');
      $('.max_booking_date_update_to', wrapper).addClass('hide');
      $('.max_booking_date_input', wrapper).removeClass('hide');
    } else {
      $('.max_booking_date_update_at', wrapper).removeClass('hide');
      $('.max_booking_date_update_to', wrapper).removeClass('hide');
      $('.max_booking_date_input', wrapper).addClass('hide');
    }
  })

  $('.info-icon').each(function() {
    $(this).popover({
      html: true,
      trigger: "hover",
      placement: 'top',
      content: function() {
        return $(this).find('.popover-body').html();
      }
    });
  });
}
