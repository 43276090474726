import { Controller } from "@hotwired/stimulus"
import axios from "axios";

class OrderCancellationFormController extends Controller {
  static targets = ['refundPercent', 'customRefundPercent', 'refundReason', 'refundableText'];

  onChangeRefundPercent() {
    const value = this.refundPercentTarget.value;
    const customPercentWrapper = this.customRefundPercentTarget.parentElement;

    if (value === 'custom') {
      if (customPercentWrapper.classList.contains('hidden')) {
        this.customRefundPercentTarget.value = '';
      }
      customPercentWrapper.classList.remove('hidden');
    } else {
      this.customRefundPercentTarget.value = value;
      customPercentWrapper.classList.add('hidden');
    }

    if (value === '100') {
      this.refundReasonTarget.classList.add('hidden');
      this.refundReasonTarget.querySelector('textarea').value = '';
    } else {
      this.refundReasonTarget.classList.remove('hidden');
    }

    this.updateRefundText();
  }

  updateRefundText() {
    const { url } = this.refundableTextTarget.dataset;
    const refundPercent = this.refundPercentTarget.value;
    const customPercent = Number(this.customRefundPercentTarget.value);
    const percentage = (refundPercent === 'custom' ? customPercent : refundPercent);

    axios
      .get(url, { params: { refund_percent: percentage} })
      .then((response) => {
        this.refundableTextTarget.innerHTML = response.data;
      });
  }
}

export default OrderCancellationFormController;
