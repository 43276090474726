import { Controller } from "@hotwired/stimulus"


class VenueSettingFieldsController extends Controller {
  connect() {
    const form = this.element;
    const disabledBookingTimeSelect = form.querySelector('#venue_disabled_booking_time_attributes_disabled');

    disabledBookingTimeSelect.addEventListener('change', (e) => toggleDisabledBookingTime());

    const toggleDisabledBookingTime = () => {
      const disabledBookingTime = disabledBookingTimeSelect.value;

      $('.enabled_booking_time').toggle(disabledBookingTime === 'false');
    }

    toggleDisabledBookingTime();

    ['max_refund_decrease', 'max_total_price_decrease', 'max_daily_price_decrease'].map((field) => {
      let enabledRuleSelect = form.querySelector(`#venue_venue_order_approval_rule_attributes_enabled_${field}`);

      enabledRuleSelect.addEventListener('change', (e) => {
        const enabledApprovalRule = enabledRuleSelect.value;
        const approvalRuleFields = form.querySelector(`#${field}`);

        if (enabledApprovalRule === 'true') {
          approvalRuleFields.classList.remove('hide');
        } else {
          approvalRuleFields.classList.add('hide');
        };
      });
    });
  }
}

export default VenueSettingFieldsController;
